<template>
  <div class="shipping-container">
    <Header></Header>

    <div class="shipping-content">
      <div class="top-image">
        <div class="title">Shipping Policy</div>
      </div>
      <div class="shipping-list">
        <div class="shipping-list-title">Shipping Policy</div>
        <div class="shipping-items">
          <div class="description">
            We provides free shipping for all orders. For our products, we will
            directly ship the items from our US, EU&other local warehouses.
            Please double check your delivery address when you place an order is
            not able to change the address after products are shipped. After you
            purchase on the website, our team will email you the tracking number
            once the product is shipped. Please frequently check your email.
          </div>
          <div class="description">
            We want to make sure your product is well packed for shipping and
            that we’ve got everything you ordered in the box. This can take up
            to three days. We process orders Mondays through Fridays, excluding
            holidays, and are unable to offer same-day shipping or guarantee
            overnight or 2nd-day delivery. So, please keep all of this in mind
            when choosing a shipping preference.
          </div>
          <div class="description">Return</div>
          <div class="description">
            Once your products purchased on this website are delivered, you have
            14 days to decide whether or not to keep them. This period starts
            after the day you received your products. You then have a further 14
            days to send the products back after you submit your return request.
            This period starts after the day you submit your return request.
            Please note that you can return the Extended Warranty within 14 days
            of purchase only by contacting our customer service team through
            Hotline, Live Chat or Email.
          </div>
        </div>
      </div>
      <el-backtop :bottom="100">
        <div
          style="
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          "
        >
          UP
        </div>
      </el-backtop>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";

export default defineComponent({
  name: "Shipping",
  components: { Header, Footer },
  setup() {
    onMounted(() => {
        document.documentElement.scrollTop = 0;
    });

    return {};
  },
});
</script>

<style lang="less">
.shipping-container {
  position: relative;
  background-color: rgba(247, 247, 247, 1);
  .shipping-content {
    .top-image {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-image: url("../../assets/images/card_bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      font-size: 20px;
      .title {
        margin-bottom: 36px;
        font-size: 60px;
      }
    }
    .shipping-list {
      position: relative;
      overflow: hidden;
      margin: 20px 200px;
      padding: 80px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 10px;
      .shipping-list-title {
        color: rgba(0, 11, 51, 1);
        font-size: 30px;
        font-weight: bold;
      }
      .shipping-items {
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: left;
        .title {
          margin-bottom: 20px;
          color: rgba(51, 51, 51, 1);
          font-size: 26px;
        }
        .description {
          line-height: 25px;
          margin-bottom: 30px;
          span {
            color: #0f4392;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
